// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-03-philosophy-index-md": () => import("./../../../src/pages/blog/03-philosophy/index.md" /* webpackChunkName: "component---src-pages-blog-03-philosophy-index-md" */),
  "component---src-pages-blog-04-blockland-solutions-index-md": () => import("./../../../src/pages/blog/04-blockland-solutions/index.md" /* webpackChunkName: "component---src-pages-blog-04-blockland-solutions-index-md" */),
  "component---src-pages-blog-06-thegraph-index-md": () => import("./../../../src/pages/blog/06-thegraph/index.md" /* webpackChunkName: "component---src-pages-blog-06-thegraph-index-md" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-disclaimer-mdx": () => import("./../../../src/pages/disclaimer.mdx" /* webpackChunkName: "component---src-pages-disclaimer-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-seasons-js": () => import("./../../../src/pages/seasons.js" /* webpackChunkName: "component---src-pages-seasons-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../../../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */)
}

